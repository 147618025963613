import React from 'react';

const MasterFooter = () => {
  return (
    <>
      <footer>
        <div style={{ backgroundColor: "#85C1E9", color: "white", padding: "10px", fontSize: "12px" }}>

          {/* सांगली शिक्षण संस्था, सांगली */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2022 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>SSS-Institute</strong></a>. All rights reserved.</span> */}

          {/* पलूस शिक्षण प्रसारक मंडळ, पलूस */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>पलूस शिक्षण प्रसारक मंडळ, पलूस</strong></a>. All rights reserved.</span> */}

          {/* लठ्ठे एज्युकेशन सोसायटी */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2022 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>लठ्ठे एज्युकेशन सोसायटी, सांगली</strong></a>. All rights reserved.</span> */}

          {/* मुस्लिम एज्युकेशन कमिटी, सांगली */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>मुस्लिम एज्युकेशन कमिटी, सांगली</strong></a>. All rights reserved.</span> */}

          {/* श्री. सिद्धेश्वर शिक्षण प्रसारक मंडळ, तुरची */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2022 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>श्री. सिद्धेश्वर शिक्षण प्रसारक मंडळ, तुरची</strong></a>. All rights reserved.</span> */}

          {/* भिलवडी शिक्षण संस्था */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>भिलवडी शिक्षण संस्था</strong></a>. All rights reserved.</span> */}

          {/* एमटीईएस मराठी शाळा, सांगली */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>एमटीईएस मराठी शाळा, सांगली</strong></a>. All rights reserved.</span> */}

          {/* पंडित विष्णू दिगंबर पलूसकर बहु. शिक्षण संस्था, पलूस */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>पंडित विष्णू दिगंबर पलूसकर बहु. शिक्षण संस्था, पलूस</strong></a>. All rights reserved.</span> */}

          {/* नागनाथ एज्युकेशन सोसायटी, नरंदे */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2023 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>नागनाथ एज्युकेशन सोसायटी, नरंदे</strong></a>. All rights reserved.</span> */}

          {/* विद्या विकास मंडळ, जामसंडे */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>विद्या विकास मंडळ, जामसंडे</strong></a>. All rights reserved.</span> */}

          {/* शिरगांव पंचक्रोशी (ता. देवगड) शिक्षण प्रसारक मंडळ, मुंबई */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>शिरगांव पंचक्रोशी (ता. देवगड) शिक्षण प्रसारक मंडळ, मुंबई</strong></a>. All rights reserved.</span> */}

          {/* देवगड एज्युकेशन बोर्ड, मुंबई */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>देवगड एज्युकेशन बोर्ड, मुंबई</strong></a>. All rights reserved.</span> */}

          {/* ज्ञानदीप शिक्षण प्रसारक मंडळ, शिरढोण */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>ज्ञानदीप शिक्षण प्रसारक मंडळ, शिरढोण</strong></a>. All rights reserved.</span> */}

          {/* पडेल ग्राम सुधारणा मंडळ, मुंबई */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>पडेल ग्राम सुधारणा मंडळ, मुंबई</strong></a>. All rights reserved.</span> */}

          {/* श्री शिवलिंगेश्वर शिक्षण संस्था, संख */}
          {/* <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>श्री शिवलिंगेश्वर शिक्षण संस्था, संख</strong></a>. All rights reserved.</span> */}

          {/* तळेबाजार पंचक्रोशी शिक्षण प्रसारक मंडळ, तळेबाजार */}
          <span className="d-block text-center text-sm-left d-sm-inline-block text-white">© 2024 <a className='text-white' href="#" target="_blank" style={{ color: "#814eff" }}><strong>तळेबाजार पंचक्रोशी शिक्षण प्रसारक मंडळ, तळेबाजार</strong></a>. All rights reserved.</span>

          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-right text-white">Developed By : <a className='text-white' href="https://technoaarv.com/" target="_blank" style={{ color: "#814eff" }}><strong>TechnoAarv Solution</strong></a></span>
        </div>
      </footer>
    </>
  )
}

export default MasterFooter;
